import React from 'react';

import {PostItems} from '../common';

type Props = {
    title: string;
    pageContext: any;
    posts: any[];
};

const NewsView = ({title, posts, pageContext}: Props): React.ReactElement => {
    posts.forEach(({node}) => {
        node.collectionPath = `/news`;
    });

    return (
        <div className="news-view">
            <h2 className="inner home-section-title">{title}</h2>
            <div className="inner posts">
                <div className="post-feed">
                    <PostItems posts={posts} isHome={true} isAuthor={false} />
                </div>
            </div>
        </div>
    );
};

export default NewsView;

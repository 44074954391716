// © 2021 Ambrose Software Inc. All rights reserved.

import React from 'react';
import {resolveUrl} from '../../utils/routing';
import useOptions from '../../utils/use-options';
import {ImgSharp} from '../common';
import {Link} from 'gatsby';
import {GhostTagNode} from '../../types';

type Props = {
    collectionPath: string;
    tag: GhostTagNode;
};

const TagCard = ({collectionPath, tag}: Props): React.ReactElement => {
    const {basePath} = useOptions();
    const url = resolveUrl(basePath, collectionPath, tag.slug, tag.url);
    const featureImg =
        (tag.featureImageSharp && tag.featureImageSharp.publicURL) ||
        tag.feature_image;
    const fluidFeatureImg =
        tag.featureImageSharp &&
        tag.featureImageSharp.childImageSharp &&
        tag.featureImageSharp.childImageSharp.fluid;

    return (
        <div className="tag-card">
            <Link to={url} className="tag-card-link">
                <ImgSharp
                    fluidClass="tag-card-image"
                    srcClass="tag-card-image"
                    fluidImg={fluidFeatureImg}
                    srcImg={featureImg}
                    title={tag.name}
                />
                <header className="tag-card-title">{tag.name}</header>
            </Link>
        </div>
    );
};

export default TagCard;
